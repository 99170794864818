import React from "react";
import * as classes from "./Grid.module.scss";
import { navigate } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
const { Projects: ProjectsClass, Project } = classes;

const Grid = ({ entries }) => {
  return (
    <main className={ProjectsClass}>
      {entries.map(entry => {
        if (entry.image == null) {
          throw new Error(`Invalid image in file '${entry.file}'`);
        }
        return (
          <div
            key={entry.path}
            className={Project}
            onClick={() => navigate(entry.path)}
          >
            <div>
              {entry.image ? (
                <GatsbyImage image={entry.image}/>
              ) : (
                <p>Image unavailable</p>
              )}
            </div>
            <h3>{entry.title}</h3>
          </div>
        );
      })}
    </main>
  );
};

export default Grid;
